import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import { useGetData } from '../../hooks/useGetData';
import DataService, { type CellData_t, type ItemAction_t } from '../../services/data.service';
import Container from 'react-bootstrap/Container';
import { Button, Col, Form, Row, Table as BootstrapTable } from 'react-bootstrap';
import RenderedCell from '../../components/rendered_cell.component';
import { useAlert } from 'react-alert';
import { TableHead } from '../table/table_head.component';
import { TableBody } from '../table/table_body.component';
import { PageSelect } from '../table/page_select.component';

const DataEntry = (props: {
  title: string;
  value: CellData_t;
}): React.ReactElement => {
  return (
    <>
      <Col md={5}>
        <Row>
          <Col md={5}>{props.title}</Col>
          <Col>
            <b>
              <RenderedCell data={props.value} />
            </b>
          </Col>
        </Row>
      </Col>
      <Col md={1}></Col>
    </>
  );
};

const Action = (props: {
  action: ItemAction_t;
  categoryName: string;
  itemName: string;
  itemId: string;
}): React.ReactElement => {
  const alert = useAlert();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setIsLoading(true);
    const data = Array.from((event.target as any).form.elements)
      .slice(0, -1) // Remove the last element (submit button)
      .reduce<Record<string, any>>((result, element) => {
        result[(element as HTMLInputElement).name] = (
          element as HTMLInputElement
        ).value;
        return result;
      }, {});

    DataService.callItemAction(
      {
        itemName: props.itemName,
        categoryName: props.categoryName,
      },
      props.itemId,
      props.action.ref,
      data,
    )
      .then((d: any) => {
        // TODO follow d?.link
        alert.info(d?.message);

        if (d?.link !== undefined) {
          if (d.link?.view === 'entity') {
            navigate(`/g/${d.link?.category}/${d.link?.name}/${d.link?.view}/${d.link?.id}`);
          }
          else if (d.link?.view === 'table') {
            navigate(`/g/${d.link?.category}/${d.link?.name}/${d.link?.view}`, { state: { filters: d.link?.filters } });
          }
        }
else {
  navigate(location, {
          state: {
            ...location.state,
            reload: (location.state?.reload ?? 1) + 1,
          },
          replace: true,
          preventScrollReset: true,
        });
        }
      })
      .catch((e) => {
        alert.error(`Failure: ${e}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // todo on title hover display description
  return (
    <Form className={'mb-3'}>
      <Row>
        <Col md={3}>
          <h4>{props.action.title}</h4>
        </Col>
        <Col>
          <Row>
            {props.action.parameters.map((param) => {
              return (
                <Col key={param.ref}>
                  <Form.Control placeholder={param.title} name={param.ref} />
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col md={3}>
          {/* TODO: Loading animation */}
          {isLoading ? (
            'Loading ...'
          ) : (
            <Button onClick={onSubmit}>Submit</Button>
          )}
        </Col>
      </Row>
    </Form>
  );
};

function SubTable(props: {
  categoryName: string;
  itemName: string;
  filters: any[];
}): React.ReactElement {
  const [page, setPage] = useState(1);

  const [data, isLoading, failed] = useGetData(
    async () =>
      await DataService.getTable(
        { categoryName: props.categoryName, itemName: props.itemName },
        page,
        10,
        null,
        props.filters,
      ),
    [props.categoryName, props.itemName, props.filters, page],
  );

  if (isLoading) {
    // TODO animated spinner
    return <div>Loading data...</div>;
  }

  if (failed !== '' || data === undefined) {
    return <div>Failed loading data: {failed}</div>;
  }

  return (
    <div>
      <BootstrapTable striped bordered hover size="sm">
        <TableHead data={data.header} />
        <TableBody data={data.data} />
      </BootstrapTable>

      <PageSelect
        page={data.page}
        perPage={data.per_page}
        totalPages={data.total_pages}
        setPage={setPage}
      />
    </div>
  );
}

function ItemPage(): React.ReactElement {
  const { categoryName, itemName, itemId } = useParams();
  const location = useLocation();

  const [data, isLoading, failed] = useGetData(
    async () =>
      await DataService.getEntity(
        {
          categoryName: categoryName ?? '',
          itemName: itemName ?? '',
        },
        itemId ?? '',
      ),
    [categoryName, itemName, location],
  );

  if (isLoading) {
    // TODO animated spinner
    return <div>Loading data...</div>;
  }

  if (
    failed !== '' ||
    data === undefined ||
    categoryName === undefined ||
    itemName === undefined ||
    itemId === undefined
  ) {
    return <div>Failed loading data: {failed}</div>;
  }

  return (
    <Container>
      <h1>{data.title}</h1>
      {data?.description !== undefined ? (
        <Markdown>{data.description}</Markdown>
      ) : null}

      <Row className={'align-items-start'}>
        {data.data.map((item, idx) => (
          <DataEntry {...item} key={`${idx}--1`} />
        ))}
      </Row>

      <Row className="mt-4">
        <Col>
          <h2>Actions</h2>
        </Col>
      </Row>
      {data.actions.map((action, idx) => (
        <Action
          key={idx}
          action={action}
          categoryName={categoryName}
          itemName={itemName}
          itemId={itemId}
        />
      ))}

      <Row className="mt-4">
        <Col>
          <h2>Tables</h2>
        </Col>
      </Row>
      {data.tables.map((table, idx) => (
        <Row key={idx}>
          <Col
            className={'ms-2'}
            style={{ transform: 'scale(0.8)', transformOrigin: '0 0' }}
          >
            <h4>
              {table.category} : {table.name}
            </h4>
            <SubTable
              categoryName={table.category}
              itemName={table.name}
              filters={table.filters}
            />
          </Col>
        </Row>
      ))}
    </Container>
  );
}

export default ItemPage;
